import LazyLoad from "../../Partials/LazyLoad/LazyLoad";
import Faqs from "./Faqs/Faqs";

const ContactRequests = LazyLoad(() =>
  import("./ContactRequest/ContactRequests")
);
const Dashboard = LazyLoad(() => import("./Dashboard/Dashboard"));
const Feedbacks = LazyLoad(() => import("./Feedbacks/Feedbacks"));
const Partners = LazyLoad(() => import("./Partners/Partners"));
const Services = LazyLoad(() => import("./Services/Services"));
const ConsultencyRequest = LazyLoad(() =>
  import("./ConsultencyRequest/ConsultencyRequest")
);
const PartnerProjectImages = LazyLoad(() =>
  import("./Partners/PartnerProjectImages/PartnerProjectImages")
);

const MainRoutes = [
  {
    path: "dashboard",
    element: <Dashboard />,
  },
  {
    path: "services",
    element: <Services />,
  },
  {
    path: "partners",
    element: <Partners />,
  },
  {
    path: "partners/partner/projectImage/:partnerId",
    element: <PartnerProjectImages />,
  },
  {
    path: "feedbacks",
    element: <Feedbacks />,
  },
  {
    path: "faqs",
    element: <Faqs />,
  },
  {
    path: "contactRequests",
    element: <ContactRequests />,
  },
  {
    path: "consultancyRequests",
    element: <ConsultencyRequest />,
  },
];

export default MainRoutes;
