import {
  CRUDTable,
  CRUDTableHeader,
  CRUDth,
  CreateButton,
  ShortTextCell,
} from "anthill-component-library/crud/builder";
import { useContext, useEffect, useState } from "react";

import {
  DeleteButton,
  EditButton,
  ViewButton,
} from "anthill-component-library/dist/buttons";
import { Modal } from "anthill-component-library/elements/elements";
import {
  useAxiosInstance,
  useUseOrientation,
} from "anthill-component-library/hooks/master";
import { IconThreeDots } from "antopolis-react-icons";

import { DeleteItem } from "anthill-component-library/dist/layouts";
import { AffiliateContext } from "../../../../context/AffiliateContext";
import { FAQ_API } from "../../../../Utilities/APIs/FaqAPis";
import "./Faq.css";
import CreateFaq from "./CreateFaq";
import UpdateFaq from "./UpdateFaq";
import ViewFaq from "./ViewFaq";

function Faqs() {
  const [faqs, setFaq] = useState(null);

  const [showActionButton, setShowActionButton] = useState(false);
  const { portrait } = useUseOrientation();

  const [targetID, setTargetID] = useState("");
  const [toggleFetch, setToggleFetch] = useState(false);

  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const { selectedOrganization } = useContext(AffiliateContext);

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetServices() {
      const { data } = await axiosInstance.get(
        `${FAQ_API}?affiliateOrganization=${selectedOrganization}`
      );
      setFaq(data);
    }
    fetchAndSetServices();
  }, [toggleFetch, axiosInstance, selectedOrganization]);

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  return (
    <div className="screen_wrapper">
      <div className="screen_header">
        <h1 className="screen_heading">FAQs ({faqs?.length} in total)</h1>

        <CreateButton
          screenTopicSingular={"FAQ"}
          setShowCreateForm={setShowCreateForm}
        />
      </div>

      <CRUDTable>
        <CRUDTableHeader>
          <CRUDth th="Title" />
          <CRUDth th="Precedence" />
          <CRUDth th="Description" />
          <CRUDth th="Actions" />
        </CRUDTableHeader>
        <tbody>
          {faqs?.length > 0 &&
            faqs?.map((faq) => (
              <tr key={faq._id}>
                <ShortTextCell text={faq?.name} />
                <ShortTextCell text={faq?.precedence} />
                <ShortTextCell
                  text={
                    faq?.description?.length > 50
                      ? `${faq?.description.slice(0, 50)}...`
                      : faq?.description
                  }
                />

                <td>
                  <div className="action_buttons_wrapper">
                    <button
                      className="three_dots"
                      onClick={() => {
                        if (targetID === faq._id) {
                          setTargetID("");
                        } else {
                          setShowActionButton(true);
                          setTargetID(faq._id);
                        }
                      }}
                    >
                      <IconThreeDots />
                    </button>
                    {!portrait && (
                      <div className="action_buttons">
                        <ViewButton
                          setShowViewSection={setShowViewSection}
                          targetID={faq._id}
                          setTargetID={setTargetID}
                        />
                        <EditButton
                          setShowUpdateForm={setShowUpdateForm}
                          targetID={faq._id}
                          setTargetID={setTargetID}
                        />
                        <DeleteButton
                          setShowDeleteSection={setShowDeleteSection}
                          targetID={faq._id}
                          setTargetID={setTargetID}
                        />
                      </div>
                    )}
                    {portrait && showActionButton && targetID === faq._id && (
                      <div className="action_buttons">
                        <ViewButton
                          setShowViewSection={setShowViewSection}
                          targetID={faq._id}
                          setTargetID={setTargetID}
                        />
                        <EditButton
                          setShowUpdateForm={setShowUpdateForm}
                          targetID={faq._id}
                          setTargetID={setTargetID}
                          setShowActionButton={setShowActionButton}
                        />
                        <DeleteButton
                          setShowDeleteSection={setShowDeleteSection}
                          targetID={faq._id}
                          setTargetID={setTargetID}
                          setShowActionButton={setShowActionButton}
                        />
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </CRUDTable>

      {showCreateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Create FAQ`}
          setShowModalContent={setShowCreateForm}
        >
          <CreateFaq
            triggerFetch={triggerFetch}
            setShowCreateForm={setShowCreateForm}
          />
        </Modal>
      )}

      {showUpdateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Update FAQ`}
          setShowModalContent={setShowUpdateForm}
        >
          <UpdateFaq
            triggerFetch={triggerFetch}
            targetID={targetID}
            setShowUpdateForm={setShowUpdateForm}
          />
        </Modal>
      )}

      {showViewSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`View FAQ`}
          setShowModalContent={setShowViewSection}
        >
          <ViewFaq triggerFetch={triggerFetch} targetID={targetID} />
        </Modal>
      )}

      {showDeleteSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`Delete FAQ`}
          setShowModalContent={setShowDeleteSection}
        >
          <DeleteItem
            api={`${FAQ_API}/`}
            targetID={targetID}
            setShowDeleteItem={setShowDeleteSection}
            triggerFetch={triggerFetch}
            axiosInstance={axiosInstance}
          />
        </Modal>
      )}
    </div>
  );
}

export default Faqs;
