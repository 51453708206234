import { useAxiosInstance } from "anthill-component-library/hooks/master";
import { useEffect, useState } from "react";
import { FAQ_API } from "../../../../Utilities/APIs/FaqAPis";

function ViewFaq({ targetID }) {
  const [faq, setFaq] = useState(null);
  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetService() {
      const { data } = await axiosInstance.get(
        FAQ_API + "/getSingleAffiliateFaq/" + targetID
      );
      setFaq(data);
    }
    fetchAndSetService();
  }, [targetID, axiosInstance]);

  return (
    <div className="crud_view_content">
      {faq && (
        <>
          <h1>Title</h1>
          <p>{faq?.name}</p>

          <h1>Precedence</h1>
          <p>{faq?.precedence}</p>

          <h1>Description</h1>
          <p>{faq?.description}</p>
        </>
      )}
    </div>
  );
}

export default ViewFaq;
