import { useContext, useState } from "react";

import { FormSubmitButton } from "anthill-component-library/dist/FormSubmitButton-196eb7e9";
import { useAxiosInstance } from "anthill-component-library/dist/hooks";
import { Form } from "anthill-component-library/dist/MediaGallery-cb8f8862";
import { ShortTextInput } from "anthill-component-library/dist/SwitchInput-069b06cf";

import {
  LongTextInput,
  NumberInput,
} from "anthill-component-library/dist/TimeInput-e94415bc";
import { AffiliateContext } from "../../../../context/AffiliateContext";
import { FAQ_API } from "../../../../Utilities/APIs/FaqAPis";

function CreateFaq({ triggerFetch, setShowCreateForm, source }) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [precedence, setPrecedence] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const { selectedOrganization } = useContext(AffiliateContext);

  const axiosInstance = useAxiosInstance();

  async function handleSubmit(e) {
    e.preventDefault();

    setIsDisabled(true);

    const itemData = new FormData();
    itemData.append("name", name);
    itemData.append("precedence", precedence);
    itemData.append("description", description);
    itemData.append("affiliateOrganization", selectedOrganization);

    const response = await axiosInstance.post(FAQ_API, itemData);
    if (response) {
      triggerFetch();
      setShowCreateForm(false);
      setIsDisabled(false);
    }
  }

  return (
    <div className="crate_form">
      <Form onSubmit={handleSubmit}>
        <div>
          <ShortTextInput
            label={`Title`}
            value={name}
            placeholder={`Enter title`}
            setState={setName}
          />
          <NumberInput
            label={`Precedence`}
            value={precedence}
            placeholder={`Enter precedence`}
            setState={setPrecedence}
          />

          <LongTextInput
            label={`Description`}
            value={description}
            placeholder={`Enter Description`}
            setState={setDescription}
          />
        </div>
        <FormSubmitButton disabled={isDisabled} text="create FAQ" />
      </Form>
    </div>
  );
}

export default CreateFaq;
