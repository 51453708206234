import { FormSubmitButton } from "anthill-component-library/dist/buttons";
import { useAxiosInstance } from "anthill-component-library/dist/hooks";
import {
  LongTextInput,
  NumberInput,
  ShortTextInput,
} from "anthill-component-library/dist/inputs";
import { Form } from "anthill-component-library/dist/layouts";
import { useEffect, useState } from "react";
import { FAQ_API } from "../../../../Utilities/APIs/FaqAPis";

function UpdateFaq({ targetID, triggerFetch, setShowUpdateForm }) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [precedence, setPrecedence] = useState("");

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetSingleClient() {
      const { data } = await axiosInstance.get(
        FAQ_API + "/getSingleAffiliateFaq/" + targetID
      );
      setName(data.name);
      setDescription(data.description);
      setPrecedence(data.precedence);
    }
    fetchAndSetSingleClient();
  }, [targetID, axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = new FormData();
    itemData.append("name", name);
    itemData.append("precedence", precedence);
    itemData.append("description", description);

    const response = await axiosInstance.patch(`${FAQ_API}/` + targetID, itemData);
    if (response) {
      triggerFetch();
      setShowUpdateForm(false);
    }
  }
  return (
    <div className="crate_form">
      <Form onSubmit={handleSubmit}>
        <div>
          <ShortTextInput
            label={`Title`}
            value={name}
            placeholder={`Enter title`}
            setState={setName}
          />
          <NumberInput
            label={`Precedence`}
            value={precedence}
            placeholder={`Enter precedence`}
            setState={setPrecedence}
          />
          <LongTextInput
            label={`Description`}
            value={description}
            placeholder={`Enter Description`}
            setState={setDescription}
          />
        </div>
        <FormSubmitButton text="Update Faq" />
      </Form>
    </div>
  );
}

export default UpdateFaq;
